import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}
/*
bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            ScullyLibModule.forRoot(),
            ChangeLanguageBarModule,
            NavigationModule,
            FooterModule,
            ApplicationModalModule,

        ),
        NavigationService,
        GlobalContentService,
        JobsService,
        ApplicationFormService,
        StandardResolver,
        StandardService,
        MetaDataService,
        { provide: LOCALE_ID, useValue: 'de-DE' },
        AnalyticsService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
}).catch((err) => console.error(err));*/

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
