<div class="shadow background-white">
    <app-progress-bar *ngIf="status === Status.Pending"></app-progress-bar>
    <div class="px-4 px-lg-5 py-5">
        <div class="row">
            <div class="col-12 d-flex justify-content-end" *ngIf="modalMode">
                <button class="button-unstyled" (click)="closeModal()">
                    <img src="./assets/images/close.svg" alt="Close Icon" class="icon-medium" />
                </button>
            </div>
        </div>
        <ng-container *ngIf="status !== Status.Success && status !== Status.TalogyError">
            <div class="row">
                <div class="col-lg-auto order-lg-1 d-none d-lg-block" *ngIf="horizontalMode">
                    <img src="/assets/images/trustpilot.svg" alt="TrustPiot" class="image-trustpilot" />
                </div>
                <div class="col order-lg-0">
                    <p class="text-size-m mb-0">{{ 'GLOBAL.APPLICATION_FORM.DEFAULT.TITLE' | translate }}</p>
                    <p>{{ 'GLOBAL.APPLICATION_FORM.DEFAULT.DESCRIPTION' | translate }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="formGroup">
                        <div class="row">
                            <div class="col-12 mt-lg-4" [class.col-lg]="horizontalMode">
                                <div class="form-floating">
                                    <input
                                        id="name"
                                        type="text"
                                        formControlName="name"
                                        placeholder="Name"
                                        autocomplete="name"
                                        class="form-control"
                                    />
                                    <label for="name">👋 Dein Name</label>
                                    <span
                                        class="text-size-xs pt-2"
                                        *ngIf="formGroup?.controls.name?.touched && formGroup?.controls.name?.hasError('required')"
                                    >
                                        {{ 'GLOBAL.APPLICATION_FORM.DEFAULT.NAME_REQUIRED_MESSAGE' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 mt-4" [class.col-lg]="horizontalMode">
                                <div class="form-floating">
                                    <input
                                        id="email"
                                        type="text"
                                        formControlName="email"
                                        placeholder="E-Mail"
                                        autocomplete="email"
                                        class="form-control"
                                    />
                                    <label for="email">✉️ Deine E-Mail Adresse</label>
                                    <span
                                        class="text-size-xs pt-2"
                                        *ngIf="formGroup?.controls.email?.touched && formGroup?.controls.email?.hasError('required')"
                                    >
                                        {{ 'GLOBAL.APPLICATION_FORM.DEFAULT.EMAIL_REQUIRED_MESSAGE' | translate }}
                                    </span>
                                    <span
                                        class="text-size-xs pt-2"
                                        *ngIf="formGroup?.controls.email?.touched && formGroup?.controls.email?.hasError('email')"
                                    >
                                        {{ 'GLOBAL.APPLICATION_FORM.DEFAULT.EMAIL_VALIDATION_MESSAGE' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div
                                class="col-12"
                                [class]="horizontalMode ? 'col-lg order-lg-0 mt-5 mt-lg-4' : 'd-flex justify-content-end mt-5'"
                            >
                                <button
                                    [disabled]="status === Status.Pending"
                                    (click)="submit()"
                                    class="btn btn-black w-100"
                                    id="apply-now"
                                >
                                    {{ 'GLOBAL.APPLY_NOW' | translate }}
                                </button>
                            </div>
                            <div class="col-12 text-center d-lg-none mt-4">
                                <p class="text-size-xs color-grey mb-3">🔒 Sichere Verbindung.</p>
                            </div>
                            <div class="col-12 text-center text-lg-start mt-lg-5" [class.order-lg-1]="horizontalMode">
                                <p class="text-size-xs color-grey mb-0">
                                    <span class="d-none d-lg-inline">🔒 Sichere Verbindung. </span>Informationen zum Datenschutz findest du
                                    <a routerLink="/datenschutz" class="text-decoration-underline">hier</a>.
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-12 mt-3">
                    <p *ngIf="status === Status.Error">
                        {{ errorMessageKey | translate }}
                    </p>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="status === Status.Success">
            <app-application-form-success [assessmentSessionUrl]="assessmentSessionUrl"></app-application-form-success>
        </ng-container>
        <div class="row" *ngIf="status === Status.TalogyError">
            <div class="col-12">
                <p class="text-size-m mb-2">{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.TITLE' | translate }}</p>
                <p>{{ 'GLOBAL.APPLICATION_FORM.DEFAULT.THANKS_DESCRIPTION' | translate }}</p>
            </div>
            <div class="col-12">
                <div class="col-12 mt-3">
                    <p *ngIf="status === Status.TalogyError">
                        {{ errorMessageKey | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
