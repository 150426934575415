import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class NavigationService {
    theme: Subject<NavigationTheme> = new Subject<NavigationTheme>();
    isThemeChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isFlyoutOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}
}

export type NavigationTheme = 'Light' | 'Pink';
