import { Component, OnInit } from '@angular/core';
import { ImageField, KeyTextField } from '@prismicio/types';
import { ModalOptions } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-video-modal',
    templateUrl: './video-modal.component.html',
    styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
    video?: KeyTextField;
    thumbnail?: ImageField;

    constructor(private options: ModalOptions) {}

    ngOnInit(): void {
        this.video = this.options?.initialState!['video'] as KeyTextField;
        this.thumbnail = this.options?.initialState!['thumbnail'] as ImageField;
    }
}
