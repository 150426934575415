import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CandidateService, RatingFormSubmission } from '../../../services/candidate.service';

@Component({
    selector: 'app-rating-form-modal',
    templateUrl: './rating-form-modal.component.html',
    styleUrls: ['./rating-form-modal.component.scss']
})
export class RatingFormModalComponent implements OnInit {
    Status = Status;
    status = Status.Default;

    formGroup?: FormGroup;
    stars?: number;

    constructor(private formBuilder: FormBuilder, private bsModalRef: BsModalRef, private candidateService: CandidateService) {}

    ngOnInit(): void {
        this.setupForm();
    }

    setupForm(): void {
        this.status = Status.Default;
        this.formGroup = this.formBuilder.group({
            name: new FormControl('', [Validators.required]),
            rating: new FormControl('', [Validators.required])
        });
    }

    submit() {
        this.status = Status.Pending;

        const ratingFormSubmission: RatingFormSubmission = {
            name: this.formGroup?.controls['name'].value,
            message: this.formGroup?.controls['rating'].value,
            rating: this.stars!,
            context: JSON.parse(localStorage.getItem('context') || '{}')
        };

        this.candidateService.createRatingFormSubmission(ratingFormSubmission).subscribe({
            next: () => {
                this.status = Status.Success;
            },
            error: () => {
                this.status = Status.Error;
            }
        });
    }

    closeModal(): void {
        this.bsModalRef.hide();
    }
}

export enum Status {
    Default,
    Pending,
    Error,
    Success
}
