import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { LinkField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { GlobalContent, GlobalContentService } from '../../../services/global-content.service';
import { NavigationService, NavigationTheme } from '../../../services/navigation.service';
import { ApplicationModalService } from '../application-modal/application-modal.service';
import { LinkComponent } from '../link/link.component';
import { RenderHtmlPipe } from '../prismic/render-html/render-html.pipe';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: true,
    imports: [LinkComponent, NgTemplateOutlet, CollapseModule, RouterLinkActive, NgClass, RenderHtmlPipe]
})
export class NavigationComponent implements OnInit {
    logoLink?: LinkField;
    globalContent?: GlobalContent | null;
    theme: NavigationTheme = 'Pink';
    isCollapsed = false;
    selectedFlyout: any;
    isNavbarHovered = false;
    @ViewChild('flyoutMenu') flyoutMenu!: ElementRef;
    @ViewChild('navigationAbsolute') navigationAbsolute!: ElementRef;

    constructor(
        private globalContentService: GlobalContentService,
        protected navigationService: NavigationService,
        private applicationModalService: ApplicationModalService,
        private renderer: Renderer2
    ) {
        this.renderer.listen('window', 'click', (event: Event) => {
            if (window?.innerWidth > 1199) {
                this.handleClickOutside(event);
            }
        });
    }

    ngOnInit(): void {
        this.globalContentService.getGlobalContent().subscribe((globalContent) => {
            this.globalContent = globalContent;
            this.logoLink = globalContent?.logo_link;
        });
        this.navigationService.theme.subscribe((theme) => {
            this.theme = theme;
        });
    }

    onMouseEnter(slice?: any) {
        if (!slice) {
            this.isNavbarHovered = false;
            this.closeOverlay();
        } else if (!this.navigationService.isFlyoutOpen.getValue() || slice !== this.selectedFlyout) {
            this.isNavbarHovered = true;
            this.openFlyout(slice);
        }
    }

    onMouseLeave(event: MouseEvent): void {
        const navigationFixedRect = this.navigationAbsolute.nativeElement.getBoundingClientRect();
        const flyoutMenuRect = this.flyoutMenu.nativeElement.getBoundingClientRect();

        if (event.clientY > flyoutMenuRect.bottom || event.clientY < navigationFixedRect.top) {
            this.isNavbarHovered = false;
            this.closeOverlay();
        }
    }

    collapse(): void {
        this.closeOverlay();
        if (window?.innerWidth < 1200) {
            this.isCollapsed = !this.isCollapsed;
        }
    }

    closeOverlay(isRoot: boolean = false): void {
        this.navigationService.isFlyoutOpen.next(false);
        this.isNavbarHovered = false;
    }

    openFlyout(slice: any) {
        this.selectedFlyout = undefined;
        if (slice === this.selectedFlyout && this.navigationService.isFlyoutOpen.getValue()) {
            this.closeOverlay();
            return;
        }
        this.selectedFlyout = slice;

        if (!this.globalContent || !this.globalContent.body) {
            return;
        }

        // @ts-ignore
        const currentIndex = this.globalContent.body.indexOf(slice);
        if (currentIndex === -1) {
            return;
        }
        this.navigationService.isFlyoutOpen.next(true);
    }

    handleClickOutside(event: Event) {
        const flyoutMenuExists = this.flyoutMenu && this.flyoutMenu.nativeElement.contains(event.target);
        const navMenuAbsoluteExists = this.flyoutMenu && this.navigationAbsolute.nativeElement.contains(event.target);
        if (this.navigationService.isFlyoutOpen.getValue() && !flyoutMenuExists && !navMenuAbsoluteExists) {
            this.collapse();
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(): void {
        if (this.navigationService.isFlyoutOpen.getValue()) {
            this.closeOverlay();
        }
    }

    openApplicationModal(): void {
        this.collapse();
        this.applicationModalService.openModal();
    }

    descriptionSerializer: HTMLMapSerializer = {
        paragraph: ({ children }) => '<p class="color-grey">' + children + '</p>'
    };
}
