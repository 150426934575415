import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ]
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
    @Input() options?: any[];
    @Input() placeholder = 'Auswählen';

    @Output() change = new EventEmitter<any>();

    @Input() selectedOption?: any;
    selectedOptions: any = [];

    onChange = (_: any) => {};
    onTouched = (_: any) => {};

    constructor() {}

    ngOnInit() {}

    writeValue(initialOption: any): void {
        this.selectedOption = initialOption;
    }

    registerOnChange(fn: (option: any) => {}): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: (option: any) => {}): void {
        this.onTouched = fn;
    }

    selectOption(selectedOption: any) {
        this.selectedOption = selectedOption;
        this.onChange(selectedOption);
        this.change.emit(selectedOption);
    }
}
