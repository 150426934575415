import { Component, Input, OnInit } from '@angular/core';
import { ImageField } from '@prismicio/types';
import { BsModalService } from 'ngx-bootstrap/modal';
import { VideoModalComponent } from '../../../video-modal/video-modal.component';

@Component({
    selector: 'app-application-form-success',
    templateUrl: './application-form-success.component.html',
    styleUrls: ['./application-form-success.component.scss']
})
export class ApplicationFormSuccessComponent implements OnInit {
    etracker?: Etracker;

    @Input() assessmentSessionUrl?: string;

    constructor(private modalService: BsModalService) {
        this.etracker = (window as { [key: string]: any })['_etracker'] as Etracker;
    }

    ngOnInit(): void {}

    openVideoModal() {
        const thumbnail: ImageField = {
            url: '/assets/images/christopher-erklaert-assessment-thumbnail.jpg',
            alt: 'Christopher Pilz erklärt Assessment',
            dimensions: {
                width: 0,
                height: 0
            },
            copyright: null
        };

        this.modalService.show(VideoModalComponent, {
            initialState: { video: 'christopher-erklaert-assessment.mp4', thumbnail },
            class: 'modal-lg'
        });
    }

    trackEventStartAssessment() {
        this.etracker?.sendEvent(new et_ClickEvent('Assessment gestartet'));
    }
}
