import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ExtraOptions, InMemoryScrollingFeature, provideRouter, withInMemoryScrolling } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { routes } from './app-routing.module';

registerLocaleData(localeDe, localeDeExtra);

const scrollConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 80]
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, inMemoryScrollingFeature),
        provideClientHydration(),
        provideAnimations(),
        provideAnimationsAsync(),
        provideHttpClient(withFetch()),
        provideLottieOptions({
            player: () => import('lottie-web')
        }),
        importProvidersFrom(),
        BsModalService,
        AnimationLoader,
        DatePipe
    ]
};
