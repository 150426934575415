import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs';

@Component({
    selector: 'app-change-language-bar',
    templateUrl: './change-language-bar.component.html',
    styleUrls: ['./change-language-bar.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class ChangeLanguageBarComponent implements OnInit {
    show = false;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((event: any) => {
            this.show = this.showBar('de', 'de');
        });
    }

    close() {
        this.show = false;
        sessionStorage.setItem('hideTopbar', 'true');
    }

    private showBar(applicationLang: string, browserLang: string): boolean {
        if (applicationLang === 'de' || sessionStorage.getItem('hideTopbar')) {
            return false;
        }

        return applicationLang !== browserLang;
    }
}
