import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RatingFormModalComponent } from './rating-form-modal.component';

@Injectable()
export class RatingFormModalService {
    private modalRef?: BsModalRef;
    constructor(private modalService: BsModalService) {}

    openModal() {
        this.modalRef = this.modalService.show(RatingFormModalComponent, { ignoreBackdropClick: true });
    }
}
