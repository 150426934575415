import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PrismicDocument } from '@prismicio/types';
import { environment } from '../../environments/environment';

@Injectable()
export class MetaDataService {
    constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private document: Document) {}

    setupMetaData(title: string, description: string, page: PrismicDocument, imageUrl?: string) {
        this.title.setTitle(`${title} | ${environment.title}`);
        this.meta.updateTag({
            name: 'description',
            content: description || ''
        });

        if (imageUrl) {
            this.meta.updateTag({ name: 'og:image', content: imageUrl || '' });
        }

        this.setupInternationalizationMetaData(page);
    }

    private setupInternationalizationMetaData(page: PrismicDocument) {
        this.setupAlternateLinks(page);
        switch (page.lang) {
            case 'de-de': {
                this.updateDocumentLanguage('de');
                break;
            }
            case 'en-us': {
                this.updateDocumentLanguage('en');
                break;
            }
        }
    }

    private setupAlternateLinks(page: PrismicDocument) {
        const links = [
            ...page.alternate_languages.map((alternateLanguage) => {
                return this.getHrefLang(alternateLanguage.uid!, page.type, alternateLanguage.lang);
            }),
            this.getHrefLang(page.uid!, page.type, page.lang)
        ];

        const alternateLanguagesDE = page.alternate_languages.find((alternateLanguage) => alternateLanguage.lang === 'de-de');
        if (alternateLanguagesDE) {
            links.push(this.getHrefLang(alternateLanguagesDE.uid!, page.type, 'default'));
        }

        links.forEach((linkData) => {
            let link: HTMLLinkElement = this.document.createElement('link');
            link.setAttribute('rel', linkData.rel);
            link.setAttribute('hreflang', linkData.hreflang);
            link.setAttribute('href', linkData.href);
            this.document.head.appendChild(link);
        });
    }

    private getHrefLang(uid: string, type: string, lang: string) {
        const typePagePath = this.getTypePagePath(type);
        const detailPagePath = this.harmonizePagePath(uid);

        switch (lang) {
            case 'de-de':
                return {
                    rel: 'alternate',
                    hreflang: 'de',
                    href: `${environment.baseUrl}${typePagePath}/${detailPagePath}`
                };
            case 'en-us':
                return {
                    rel: 'alternate',
                    hreflang: 'en',
                    href: `${environment.baseUrl}/en${typePagePath}/${detailPagePath}`
                };
            default:
                return {
                    rel: 'alternate',
                    hreflang: 'x-default',
                    href: `${environment.baseUrl}${typePagePath}/${detailPagePath}`
                };
        }
    }

    private getTypePagePath(type: string): string {
        switch (type) {
            case 'article':
                return '/artikel';
            case 'job':
                return '/jobs';
            default:
                return '';
        }
    }

    private harmonizePagePath(uid: string): string {
        if (uid === 'startseite') {
            return '';
        }

        return `${uid}/`;
    }

    private updateDocumentLanguage(languageCode: string) {
        document.documentElement.setAttribute('lang', languageCode);
    }
}
