import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ApplicationFormModule } from '../application-form/application-form.module';
import { ApplicationModalComponent } from './application-modal.component';
import { ApplicationModalService } from './application-modal.service';

const modules = [CommonModule, RouterModule, ModalModule.forRoot(), ApplicationFormModule];

const components = [ApplicationModalComponent];

@NgModule({
    imports: [...modules],
    declarations: [...components],
    exports: [...components],
    providers: [ApplicationModalService]
})
export class ApplicationModalModule {}
