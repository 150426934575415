import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { GlobalContent, GlobalContentService } from '../../../services/global-content.service';
import { ApplicationModalService } from '../application-modal/application-modal.service';
import { LinkComponent } from '../link/link.component';
import { RenderHtmlPipe } from '../prismic/render-html/render-html.pipe';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [RouterLink, LinkComponent, RenderHtmlPipe]
})
export class FooterComponent implements OnInit {
    globalContent?: GlobalContent;

    constructor(
        private route: ActivatedRoute,
        private globalContentService: GlobalContentService,
        private applicationModalService: ApplicationModalService
    ) {}

    ngOnInit(): void {
        this.globalContentService.getGlobalContent().subscribe((globalContent) => {
            if (globalContent) {
                this.globalContent = globalContent;
            }
        });
    }

    htmlSerializer: HTMLMapSerializer = {
        paragraph: ({ children }) => '<p class="color-white text-size-m">' + children + '</p>'
    };

    openApplicationModal() {
        this.applicationModalService.openModal();
    }
}
