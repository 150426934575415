import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationModalComponent } from './application-modal.component';

@Injectable()
export class ApplicationModalService {
    private modalRef?: BsModalRef;
    etracker?: Etracker;
    constructor(private modalService: BsModalService) {
        this.etracker = (window as { [key: string]: any })['_etracker'] as Etracker;
    }

    openModal() {
        this.etracker?.sendEvent(new et_ClickEvent('Bewerbungsformular geöffnet'));
        this.modalRef = this.modalService.show(ApplicationModalComponent, {
            ignoreBackdropClick: true
        });
    }
}
