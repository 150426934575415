import { Injectable } from '@angular/core';

@Injectable()
export class AnalyticsService {
    etracker?: Etracker;
    constructor() {
        this.etracker = (window as { [key: string]: any })['_etracker'] as Etracker;
    }

    trackClickEvent(eventName: string) {
        this.etracker?.sendEvent(new et_ClickEvent(eventName));
    }

    trackDownloadEvent(fileName: string) {
        this.etracker?.sendEvent(new et_DownloadEvent(fileName));
    }

    trackLinkEvent(fileName: string) {
        this.etracker?.sendEvent(new et_LinkEvent(fileName));
    }

    trackVideoStartEvent(videoName: string) {
        this.etracker?.sendEvent(new et_VideoStartEvent(videoName));
    }
}
