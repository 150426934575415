import { createClient, getRepositoryEndpoint } from '@prismicio/client';
import { Environment } from './environment.model';
import * as gitRef from './versions.json';

export const environment: Environment = {
    production: true,
    title: 'gotoitcareer',
    baseUrl: 'https://test.gotoitcareer.com',
    backendUrl: 'https://develop-dot-gotoitcareer.ey.r.appspot.com',
    oldBackendUrl: 'https://gotoitcareer.ey.r.appspot.com',
    stage: 'DEV',
    prismic: {
        client: createClient(getRepositoryEndpoint('gotoitcareer')),
        url: 'https://gotoitcareer.cdn.prismic.io/api/v2',
        previewKey: 'gotoitcareer.prismic.io'
    },
    git: {
        ref: gitRef
    },
    applicationConfig: {
        jobId: '9d219ffa-84fd-4d66-be0e-3edee41fabd8',
        organizationId: 'a8f0f26e-7a39-4620-8662-7a3c12d197a7'
    }
};
