import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VideoModalComponent } from './video-modal.component';

@NgModule({
    declarations: [VideoModalComponent],
    imports: [RouterModule, CommonModule]
})
export class VideoModalModule {}
