import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ApplicationFormService } from '../../../services/application-form.service';
import { CandidateCreateContext, CandidateService, CreatableCandidate } from '../../../services/candidate.service';
import { GlobalContentService } from '../../../services/global-content.service';
import { Job } from '../../../services/jobs.service';

@Component({
    selector: 'app-application-form',
    templateUrl: './application-form.component.html',
    styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {
    etracker?: Etracker;

    @Input() horizontalMode = false;
    @Input() modalMode = false;
    @Output() close: EventEmitter<void> = new EventEmitter();

    job?: string;
    organization?: string;

    hideDataPrivacyOption = false;
    DataPrivacyFieldId: string = Math.random().toString(36);

    Status = Status;
    status = Status.Default;
    errorMessageKey?: string;

    formGroup?: FormGroup;
    currentLang?: string;

    assessmentSessionUrl?: string;

    constructor(
        private formBuilder: FormBuilder,
        private applicationFormService: ApplicationFormService,
        private candidateService: CandidateService,
        private globalContentService: GlobalContentService,
        private translateService: TranslateService
    ) {
        this.etracker = (window as { [key: string]: any })['_etracker'] as Etracker;
    }

    ngOnInit(): void {
        this.currentLang = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe((event) => {
            this.currentLang = event.lang;
        });

        const applicationFormData = this.applicationFormService.getApplicationFormData();
        this.job = applicationFormData.job;
        this.organization = applicationFormData.organization;

        this.setupForm();
        this.hideDataPrivacyOption = this.horizontalMode;
    }

    setupForm(): void {
        this.status = Status.Default;
        this.formGroup = this.formBuilder.group({
            name: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email])
        });
    }

    submit() {
        if (this.formGroup?.invalid) {
            for (var i in this.formGroup.controls) {
                this.formGroup.controls[i].markAsTouched();
            }
            return;
        }

        const candidate: CreatableCandidate = {
            name: this.formGroup?.controls['name'].value,
            email: this.formGroup?.controls['email'].value,
            job: this.job || environment.applicationConfig.jobId,
            organization: this.organization || environment.applicationConfig.organizationId,
            returnAssessmentSession: true,
            createAssessmentSession: true,
            preferredLanguage: this.currentLang || 'de',
            context: this.getContext()
        };

        this.status = Status.Pending;
        this.candidateService.createCandidate(candidate).subscribe({
            next: (response) => {
                this.etracker?.sendEvent(new et_ClickEvent('Beworben'));
                this.assessmentSessionUrl = response.assessmentSessionUrl;
                this.status = Status.Success;
            },
            error: (error) => {
                this.status = Status.Error;
                switch (error.status) {
                    case 500: {
                        switch (error.error.code) {
                            case '40040AC':
                                this.status = Status.TalogyError;
                                this.errorMessageKey = 'GLOBAL.APPLICATION_FORM.DEFAULT.ERROR_AC_CAN_NOT_CREATED';
                                break;
                            case '40040EE':
                                this.errorMessageKey = 'GLOBAL.APPLICATION_FORM.DEFAULT.ERROR_CANDIDATE_ALREADY_EXISTS';
                                break;
                            default:
                                this.errorMessageKey = 'GLOBAL.APPLICATION_FORM.DEFAULT.ERROR_GENERAL';
                                break;
                        }
                        break;
                    }
                    default: {
                        this.errorMessageKey = 'GLOBAL.APPLICATION_FORM.DEFAULT.ERROR_GENERAL';
                    }
                }
            }
        });
    }

    getContext(): CandidateCreateContext {
        return {
            firstVisitPage: localStorage.getItem('firstVisitPage') || '',
            firstVisitDate: localStorage.getItem('firstVisitDate') || ''
        };
    }

    closeModal(): void {
        this.close.emit();
    }
}

export enum Status {
    Default,
    Pending,
    Error,
    Success,
    TalogyError
}
