<div class="position-relative">
    <nav class="navbar navbar-expand-xl" [class]="theme" [ngClass]="isNavbarHovered ? 'Light' : ''" #navigationAbsolute>
        <div class="container py-2 py-xl-4 px-xl-0">
            <app-link
                [link]="logoLink"
                cssClass="navbar-brand d-flex align-items-center"
                (click)="closeOverlay(true)"
                (mouseenter)="closeOverlay(true)"
            >
                @if (globalContent){
                <img
                    [src]="(theme === 'Light' || isNavbarHovered) ? './assets/images/gotoitcareer-powered-by-accadis-black.svg' : './assets/images/gotoitcareer-powered-by-accadis-white.svg'"
                    alt="gotoitcareer Logo"
                    style="height: 55px"
                />
                }
            </app-link>
            <button
                class="navbar-toggler"
                type="button"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                (click)="collapse()"
            >
                <img [src]="isCollapsed ? '/assets/images/close.svg' : '/assets/images/menu.svg'" alt="Menu" />
            </button>
            <div class="collapse navbar-collapse" id="navbarNav" [class.show]="isCollapsed">
                <ul class="navbar-nav ms-auto d-flex align-items-center ps-xl-5">
                    @if (globalContent?.body; as sliceZone) { @for (slice of $any(sliceZone); track slice) { @if (slice.slice_type ===
                    'menu-item') {
                    <li class="nav-item mt-5 mt-xl-0 me-xl-5">
                        @if (slice.items.length > 0) {
                        <div class="btn-group d-none d-xl-flex" (mouseenter)="onMouseEnter(slice)">
                            <button
                                class="button-unstyled d-inline-flex align-items-center"
                                [class.fw-bold]="slice === selectedFlyout && navigationService.isFlyoutOpen.getValue()"
                            >
                                {{ slice.primary.title }}
                                <ng-container *ngTemplateOutlet="arrowDown; context: { slice: slice }"></ng-container>
                            </button>
                        </div>
                        <!-- Mobile: Collapse -->
                        <div class="d-xl-none text-center">
                            <button
                                type="button"
                                class="button-unstyled dropdown-toggle color-black"
                                (click)="slice.primary.isOpen = !slice.primary.isOpen"
                                [attr.aria-expanded]="!!slice.primary.isOpen"
                                aria-controls="collapseBasic"
                            >
                                {{ slice.primary.title }}
                                <span class="caret"></span>
                            </button>
                            <div id="collapseBasic" [collapse]="!slice.primary.isOpen">
                                <ul class="list-unstyled">
                                    @for (menuItem of slice.items; track menuItem) {
                                    <li class="mt-4">
                                        <app-link [link]="$any(menuItem).link" routerLinkActive="active-link" (click)="collapse()">
                                            {{ $any(menuItem).title }}
                                        </app-link>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        } @else {
                        <app-link
                            [link]="slice.primary.link"
                            [anker]="slice?.primary?.anker"
                            routerLinkActive="active-link"
                            (click)="collapse()"
                            (mouseenter)="onMouseEnter()"
                            [cssClass]="'menu-link'"
                        >
                            {{ slice.primary.title }}
                        </app-link>
                        }
                    </li>
                    } } }
                    <li class="mt-3 mt-xl-0 mt-5 mt-xl-0 prominent">
                        <button class="btn btn-black" (click)="openApplicationModal()">Jetzt bewerben</button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <nav
        class="position-absolute w-100 bg-white flyout d-none d-lg-block"
        style="top: 126px; z-index: 1050"
        #flyoutMenu
        [ngClass]="selectedFlyout && navigationService.isFlyoutOpen.getValue() ? 'flyout-open' : 'flyout-closed'"
        (mouseleave)="onMouseLeave($event)"
    >
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-3 pe-xxl-5 position-relative">
                    <p class="text-size-m color-black mb-2" style="margin-top: 40px">
                        @if (selectedFlyout?.primary?.link?.uid || selectedFlyout?.primary?.link?.url) {
                        <app-link
                            class="fw-bold"
                            cssClass="stretched-link scale-on-hover"
                            [link]="selectedFlyout?.primary?.link"
                            (click)="closeOverlay()"
                        >
                            {{
                                selectedFlyout?.primary?.flyout_title
                                    ? selectedFlyout?.primary?.flyout_title
                                    : selectedFlyout?.primary?.title
                            }}
                        </app-link>
                        } @else {
                        {{ selectedFlyout?.primary?.flyout_title ? selectedFlyout?.primary?.flyout_title : selectedFlyout?.primary?.title }}
                        }
                        <ng-template #text>
                            {{
                                selectedFlyout?.primary?.flyout_title
                                    ? selectedFlyout?.primary?.flyout_title
                                    : selectedFlyout?.primary?.title
                            }}
                        </ng-template>
                    </p>
                    <div [innerHtml]="selectedFlyout?.primary?.description | renderHtml : descriptionSerializer"></div>
                </div>
                <div class="col-lg-9">
                    <div class="row gy-5 gx-1 gx-xl-5">
                        @for (link of selectedFlyout?.items; track link; let i = $index) {
                        <div class="col-md-6 col-lg-4">
                            <div class="flyout-link position-relative mt-2 scale-on-hover">
                                <div style="height: 40px">
                                    @if (link?.badge?.length > 0) {
                                    <span class="badge-outline tiny">{{ link?.badge }}</span>
                                    }
                                </div>
                                <div class="d-flex align-items-start">
                                    <img [src]="link?.icon?.url" [alt]="link?.icon?.alt" class="me-3" style="height: 30px" />
                                    <div>
                                        <app-link
                                            [link]="link?.link"
                                            cssClass="stretched-link"
                                            class="fw-bold mb-2 fs-20"
                                            (click)="closeOverlay()"
                                        >
                                            {{ link?.title }}
                                        </app-link>
                                        <div class="mt-3" [innerHtml]="link?.description | renderHtml : descriptionSerializer"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<ng-template #arrowDown let-slice="slice">
    <svg
        [class]="slice === selectedFlyout && navigationService.isFlyoutOpen.getValue() ? 'toggle-up' : 'toggle-down'"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="512"
        height="512"
        x="0"
        y="0"
        viewBox="0 0 612.007 612.007"
        style="enable-background: new 0 0 512 512"
        xml:space="preserve"
        class="toggle ms-2"
    >
        <g>
            <path
                d="M596.168 130.647c-21.119-21.169-55.382-21.169-76.526 0L306.013 366.44 92.384 130.647c-21.144-21.169-55.382-21.169-76.525 0-21.144 21.169-21.144 55.458 0 76.627l248.504 274.31c11.438 11.438 26.672 16.482 41.651 15.54 14.953.942 30.213-4.102 41.65-15.54l248.505-274.31c21.118-21.169 21.118-55.457-.001-76.627z"
                fill="#000"
                opacity="1"
                data-original="#000000"
            ></path>
        </g>
    </svg>
</ng-template>
