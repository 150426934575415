import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { PrismicDocument } from '@prismicio/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StandardPage, StandardService } from './standard.service';

@Injectable()
export class StandardResolver implements Resolve<PrismicDocument<StandardPage>> {
    constructor(private readonly standardService: StandardService, private readonly router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PrismicDocument<StandardPage>> {
        const locale = route.data['locale'];

        return this.standardService.getStandardPage(route.paramMap.get('uid') || route.data['uid'], locale).pipe(
            tap((response) => {
                if (!response) {
                    this.router.navigate(['/seite-nicht-gefunden']);
                }
            })
        );
    }
}
