<ng-container [ngSwitch]="action">
    <!-- Application -->
    <ng-container *ngSwitchCase="'Bewerben'">
        <div class="link" role="button" [class]="cssClass || 'btn btn-black mt-4'" (click)="applicationModalService.openModal()">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    </ng-container>

    <!-- Rating -->
    <ng-container *ngSwitchCase="'Bewerten'">
        <div class="link" role="button" [class]="cssClass || 'btn btn-black mt-4'" (click)="ratingFormModalService.openModal()">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <ng-container [ngSwitch]="link?.link_type" *ngIf="link">
            <ng-container *ngSwitchCase="'Document'">
                <!-- Standard-Page -->
                <ng-container [ngSwitch]="link.type">
                    <a
                        *ngSwitchCase="'standard-page'"
                        [hidden]="!link.uid"
                        [routerLink]="lang + (link.uid === 'startseite' ? '' : '/' + link.uid)"
                        [routerLinkActiveOptions]="{ exact: true }"
                        [routerLinkActive]="routerLinkActive"
                        [class]="cssClass"
                        [fragment]="anker"
                    >
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </a>
                </ng-container>

                <!-- Partner -->
                <ng-container [ngSwitch]="link.type">
                    <a
                        *ngSwitchCase="'partner-company'"
                        [hidden]="!link.uid"
                        [routerLink]="lang + (lang === '/en' ? '/partners/' : '/partner/') + link.uid"
                        [routerLinkActive]="routerLinkActive"
                        [class]="cssClass"
                        [fragment]="anker"
                    >
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </a>

                    <a
                        *ngSwitchCase="'education-partner'"
                        [hidden]="!link.uid"
                        [routerLink]="lang + (lang === '/en' ? '/education-partners/' : '/bildungspartner/') + link.uid"
                        [routerLinkActive]="routerLinkActive"
                        [class]="cssClass"
                        [fragment]="anker"
                    >
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </a>
                </ng-container>

                <!-- Jobs -->
                <ng-container [ngSwitch]="link.type">
                    <a
                        *ngSwitchCase="'job'"
                        [hidden]="!link.uid"
                        [routerLink]="lang + '/jobs/' + link.uid"
                        [routerLinkActive]="routerLinkActive"
                        [class]="cssClass"
                    >
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </a>
                </ng-container>

                <!-- Article -->
                <ng-container [ngSwitch]="link.type">
                    <a
                        *ngSwitchCase="'article'"
                        [hidden]="!link.uid"
                        [routerLink]="lang + '/artikel/' + link.uid"
                        [routerLinkActive]="routerLinkActive"
                        [class]="cssClass"
                    >
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </a>
                </ng-container>
            </ng-container>

            <!-- Web -->
            <a
                *ngSwitchCase="'Web'"
                [hidden]="!link.url"
                [href]="link.url"
                [target]="link.target"
                [class]="cssClass"
                rel="noopener nofollow"
            >
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </a>

            <!-- Media -->
            <a
                *ngSwitchCase="'Media'"
                [hidden]="!link.url"
                [href]="link.url"
                [target]="link.target"
                [class]="cssClass"
                rel="noopener nofollow"
            >
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </a>
        </ng-container>
    </ng-container>

    <ng-template #content>
        <ng-content></ng-content>
    </ng-template>
</ng-container>
