import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'en',
        loadChildren: () => import('./modules/standard/standard.module').then((m) => m.StandardModule),
        data: getLocalizeData('en')
    },
    {
        path: 'en/partners',
        loadChildren: () => import('./modules/partner-companies/partner-companies.module').then((m) => m.PartnerCompaniesModule),
        data: { uid: 'partners', ...getLocalizeData('en') }
    },
    {
        path: 'en/education-partners',
        loadChildren: () => import('./modules/education-partner/education-partner.routing').then((m) => m.EDUCATION_PARTNER_ROUTES),
        data: { uid: 'education-partners', ...getLocalizeData('en') }
    },
    {
        path: 'en/jobs',
        loadChildren: () => import('./modules/jobs/jobs.module').then((m) => m.JobsModule),
        data: getLocalizeData('en')
    },
    {
        path: 'en/candidates',
        loadChildren: () => import('./modules/candidates/candidates.module').then((m) => m.CandidatesModule),
        data: getLocalizeData('en')
    },
    {
        path: 'en/:uid',
        loadChildren: () => import('./modules/standard/standard.module').then((m) => m.StandardModule),
        data: getLocalizeData('en')
    },
    {
        path: '',
        loadChildren: () => import('./modules/standard/standard.module').then((m) => m.StandardModule),
        data: getLocalizeData('de')
    },
    {
        path: 'partner',
        loadChildren: () => import('./modules/partner-companies/partner-companies.module').then((m) => m.PartnerCompaniesModule),
        data: { uid: 'partner', ...getLocalizeData('de') }
    },
    {
        path: 'bildungspartner',
        loadChildren: () => import('./modules/education-partner/education-partner.routing').then((m) => m.EDUCATION_PARTNER_ROUTES),
        data: { uid: 'bildungspartner', ...getLocalizeData('de') }
    },
    {
        path: 'jobs',
        loadChildren: () => import('./modules/jobs/jobs.module').then((m) => m.JobsModule),
        data: getLocalizeData('de')
    },
    {
        path: 'artikel',
        loadChildren: () => import('./modules/articles/articles.module').then((m) => m.ArticlesModule),
        data: getLocalizeData('de')
    },
    {
        path: 'kandidaten',
        loadChildren: () => import('./modules/candidates/candidates.module').then((m) => m.CandidatesModule),
        data: getLocalizeData('de')
    },
    {
        path: 'seite-nicht-gefunden',
        loadChildren: () => import('./modules/not-found/not-found.module').then((m) => m.NotFoundModule),
        data: getLocalizeData('de')
    },
    {
        path: ':uid',
        loadChildren: () => import('./modules/standard/standard.module').then((m) => m.StandardModule),
        data: getLocalizeData('de')
    }
];

function getLocalizeData(language: string): { language: string; locale: string } {
    switch (language) {
        case 'en':
            return {
                language: 'en',
                locale: 'en-us'
            };
        default:
            return {
                language: 'de',
                locale: 'de-de'
            };
    }
}

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
            initialNavigation: 'enabledBlocking',
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
