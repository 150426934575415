import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { CandidateService } from '../../../services/candidate.service';
import { DropdownModule } from '../dropdown/dropdown.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { VideoModalModule } from '../video-modal/video-modal.module';
import { ApplicationFormComponent } from './application-form.component';
import { ApplicationFormSuccessComponent } from './components/application-form-success/application-form-success.component';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
    return player;
}

const modules = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TranslateModule,
    ProgressBarModule,
    LottieModule.forRoot({ player: playerFactory }),
    VideoModalModule
];

const components = [ApplicationFormComponent, ApplicationFormSuccessComponent];

@NgModule({
    imports: [...modules],
    declarations: [...components],
    providers: [CandidateService],
    exports: [...components]
})
export class ApplicationFormModule {}
