import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApplicationModalModule } from '../application-modal/application-modal.module';
import { RatingFormModalModule } from '../rating-form-modal/rating-form-modal.module';
import { LinkComponent } from './link.component';

@NgModule({
    declarations: [LinkComponent],
    imports: [RouterModule, CommonModule, RatingFormModalModule, ApplicationModalModule],
    exports: [LinkComponent]
})
export class LinkModule {}
