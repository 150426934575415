import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LinkModule } from '../link/link.module';
import { RenderHtmlModule } from '../prismic/render-html/render-html.module';
import { FooterComponent } from './footer.component';

@NgModule({
    declarations: [FooterComponent],
    imports: [RouterModule, CommonModule, RenderHtmlModule, LinkModule, TranslateModule],
    providers: [],
    exports: [FooterComponent]
})
export class FooterModule {}
