<div class="btn-group w-100" dropdown>
    <button dropdownToggle type="button" class="form-select">
        {{ selectedOption?.title || selectedOption || placeholder }}
    </button>
    <ul *dropdownMenu class="dropdown-menu w-100" role="menu" [hidden]="options.length < 1">
        <li *ngFor="let option of options" (click)="selectOption(option)" class="p-3" role="menuitem">
            {{ option.title || option }}
        </li>
    </ul>
</div>
