import { Pipe, PipeTransform } from '@angular/core';
import { asHTML, HTMLFunctionSerializer, HTMLMapSerializer } from '@prismicio/helpers';
import { RichTextField } from '@prismicio/types';

@Pipe({
    name: 'renderHtml'
})
export class RenderHtmlPipe implements PipeTransform {
    transform(value: RichTextField, serializer?: HTMLMapSerializer | HTMLFunctionSerializer): unknown {
        return asHTML(value, undefined, serializer);
    }
}
