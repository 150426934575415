<div class="row d-flex justify-content-center">
    <div class="col-lg-10 text-center">
        <h3>
            <span class="text-size-xl">🎉</span> <br />
            {{ 'GLOBAL.APPLICATION_FORM.SUCCESS.TITLE' | translate }}
        </h3>
        <p>
            {{ 'GLOBAL.APPLICATION_FORM.SUCCESS.DESCRIPTION' | translate }}
        </p>
        <button (click)="openVideoModal()" class="btn btn-black-border mt-4">
            {{ 'GLOBAL.APPLICATION_FORM.SUCCESS.WATCH_VIDEO_BUTTON' | translate }}
        </button>
    </div>
</div>
<div class="row position-relative mt-5">
    <div class="process-line d-none d-lg-block"></div>
    <div class="col-lg-4 mt-4 mt-lg-5 d-flex flex-column text-center">
        <div class="process-step mx-auto">
            <ng-lottie [options]="{ path: '/assets/lottie/assessment.json' }"></ng-lottie>
        </div>
        <p class="mt-4">
            <b>{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.STEP_01_TITLE' | translate }}</b
            ><br />
            <span class="text-size-xs">{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.STEP_01_DESCRIPTION' | translate }}</span>
        </p>
    </div>
    <div class="col-lg-4 mt-4 mt-lg-5 d-flex flex-column text-center">
        <div class="process-step mx-auto">
            <ng-lottie [options]="{ path: '/assets/lottie/personal-data.json' }"></ng-lottie>
        </div>
        <p class="mt-4">
            <b>{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.STEP_02_TITLE' | translate }}</b
            ><br />
            <span class="text-size-xs">{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.STEP_02_DESCRIPTION' | translate }}</span>
        </p>
    </div>
    <div class="col-lg-4 mt-4 mt-lg-5 d-flex flex-column text-center">
        <div class="process-step mx-auto">
            <ng-lottie [options]="{ path: '/assets/lottie/live-interview.json' }"></ng-lottie>
        </div>
        <p class="mt-4">
            <b>{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.STEP_03_TITLE' | translate }}</b
            ><br />
            <span class="text-size-xs">{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.STEP_03_DESCRIPTION' | translate }}</span>
        </p>
    </div>
    <div class="col-12 text-center mt-5">
        <a
            [href]="assessmentSessionUrl"
            target="_blank"
            rel="noopener nofollow"
            class="btn btn-black"
            id="start-assessment-session"
            (click)="trackEventStartAssessment()"
        >
            {{ 'GLOBAL.APPLICATION_FORM.SUCCESS.START_ASSESSMENT_BUTTON' | translate }}
        </a>
    </div>
</div>
<div class="row d-flex justify-content-center mt-5 mt-lg-6">
    <div class="col-lg-10 text-center">
        <h4>{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.NO_TIME_TITLE' | translate }}</h4>
        <p>{{ 'GLOBAL.APPLICATION_FORM.SUCCESS.NO_TIME_DESCRIPTION' | translate }}</p>
    </div>
</div>
