import { Routes } from '@angular/router';
import { StandardComponent } from './modules/standard/standard.component';
import { standardResolver } from './modules/standard/standard.resolver';

export const routes: Routes = [
    {
        path: '',
        //loadChildren: () => import('./modules/standard/standard.routes').then((mod) => mod.STANDARD_ROUTES),
        component: StandardComponent,
        resolve: {
            standardPage: standardResolver
        }
    },
    {
        path: 'partner',
        loadChildren: () => import('./modules/partner-companies/partner-companies.routes').then((m) => m.PARTNER_COMPANIES_ROUTES),
        data: { uid: 'partner'}
    },
    {
        path: 'bildungspartner',
        loadChildren: () => import('./modules/education-partner/education-partner.routing').then((m) => m.EDUCATION_PARTNER_ROUTES),
        data: { uid: 'bildungspartner'}
    },
    {
        path: 'jobs',
        loadChildren: () => import('./modules/jobs/jobs-routing').then((m) => m.JOB_ROUTES),
    },
    {
        path: 'preview',
        loadChildren: () => import('./modules/preview/preview.routing').then((mod) => mod.PREVIEW_ROUTES)
    },
    {
        path: 'artikel',
        loadChildren: () => import('./modules/articles/articles-routing').then((m) => m.ARTICLES_ROUTES),
    },
    {
        path: 'lexikon',
        loadChildren: () => import('./modules/lexicon/lexicon.routes').then((m) => m.LEXICON_ROUTES),
    },
    {
        path: 'seite-nicht-gefunden',
        loadChildren: () => import('./modules/not-found/not-found-routing').then((mod) => mod.NOT_FOUND_ROUTES),
    },
    {
        path: ':uid',
        loadChildren: () => import('./modules/standard/standard.routes').then((mod) => mod.STANDARD_ROUTES),
    }
];
