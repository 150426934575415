<div class="background-black" *ngIf="show">
    <div class="container">
        <div class="row">
            <div class="col-12 my-1 d-flex align-items-center justify-content-between">
                <a class="color-white text-size-xs" routerLink="/" (click)="close()">Klicke hier um zur deutschen Version zu wechseln</a>
                <button class="button-unstyled" (click)="close()">
                    <img src="./assets/images/close_white.svg" alt="" />
                </button>
            </div>
        </div>
    </div>
</div>
