<footer class="background-pink py-5 mt-5 mt-lg-6">
    <div class="container">
        <div class="row mt-5 d-flex justify-content-between">
            <div class="col-lg-6 pe-lg-5 mb-5 mb-lg-0">
                <div [innerHTML]="globalContent?.footer_title | renderHtml : htmlSerializer"></div>
                <div [innerHTML]="globalContent?.footer_description | renderHtml"></div>

                <button class="btn btn-black" (click)="openApplicationModal()">
                    {{ 'FOOTER.APPLY_NOW_BUTTON' | translate }}
                </button>

                <!--
                <div class="row">
                    <div class="col" *ngFor="let signet of globalContent?.signets">
                        <a [href]="signet.link.url" rel="noopener nofollow" target="_blank">
                            <img [src]="signet.signet.url" [alt]="signet.signet.alt" class="icon-big" />
                        </a>
                    </div>
                </div> -->
                <div class="row mt-5">
                    <div class="col">
                        <h4 class="color-white mb-3">{{ 'FOOTER.LANGUAGE_TITLE' | translate }}</h4>
                        <p>
                            <a routerLink="/" class="mx-2"> DE </a>
                            |
                            <a routerLink="/en" class="mx-2"> EN </a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mb-5">
                <h4 class="color-white">{{ 'FOOTER.LINKS_TITLE' | translate }}</h4>
                <ul>
                    <li *ngFor="let link of globalContent?.footer_links">
                        <app-link [link]="link.link" cssClass="color-white">
                            {{ link.title }}
                        </app-link>
                    </li>
                </ul>
                <h4 class="color-white pt-4">{{ 'FOOTER.LEGAL_TITLE' | translate }}</h4>
                <ul>
                    <li *ngFor="let link of globalContent?.footer_links_legal">
                        <app-link [link]="link.link" cssClass="color-white">
                            {{ link.title }}
                        </app-link>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 mb-5">
                <h4 class="color-white">{{ 'FOOTER.CONTACT_TITLE' | translate }}</h4>
                <ul>
                    <li><div [innerHTML]="globalContent?.address | renderHtml"></div></li>
                    <li>
                        <a [href]="'mailto:' + globalContent?.email" class="color-white text-size-s">{{ globalContent?.email }}</a>
                    </li>
                </ul>
                <p class="color-white mt-4">
                    {{ 'FOOTER.SUPPORT_DESCRIPTION' | translate }}
                    <a class="color-white text-decoration-underline" href="mailto:support@gotoitcareer.com">support@gotoitcareer.com</a>
                </p>
            </div>
        </div>
        <hr />
        <div class="row mt-5">
            <div class="col-lg-6">
                <img src="./assets/images/logo_weiss.svg" alt="gotoitcareer Logo" class="brand" />
            </div>
            <div class="col-lg-6 mt-5 mt-lg-0 d-flex justify-content-lg-end">
                <ul class="legal-links">
                    <li class="me-4">
                        <a [href]="globalContent?.instagram.url" target="_blank" rel="noopener nofollow">
                            <img src="/assets/images/instagram.svg" alt="Instagram Icon" class="social-media-icon" />
                        </a>
                    </li>
                    <li class="me-4">
                        <a [href]="globalContent?.facebook.url" target="_blank" rel="noopener nofollow">
                            <img src="/assets/images/facebook.svg" alt="Facebook Icon" class="social-media-icon" />
                        </a>
                    </li>
                    <li class="me-4">
                        <a [href]="globalContent?.linkedin.url" target="_blank" rel="noopener nofollow">
                            <img src="/assets/images/linkedin.svg" alt="LinkedIn Icon" class="social-media-icon" />
                        </a>
                    </li>
                    <li class="me-4">
                        <a [href]="globalContent?.xing.url" target="_blank" rel="noopener nofollow">
                            <img src="/assets/images/xing.svg" alt="Xing Icon" class="social-media-icon" />
                        </a>
                    </li>
                    <li class="me-4">
                        <a [href]="globalContent?.youtube.url" target="_blank" rel="noopener nofollow">
                            <img src="/assets/images/youtube.svg" alt="Youtube Icon" class="social-media-icon" />
                        </a>
                    </li>
                    <li>
                        <a [href]="globalContent?.twitter.url" target="_blank" rel="noopener nofollow">
                            <img src="/assets/images/twitter.svg" alt="Twitter Icon" class="social-media-icon" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
