import { Injectable } from '@angular/core';
import {
    BooleanField,
    DateField,
    FilledLinkToDocumentField,
    GroupField,
    ImageField,
    KeyTextField,
    PrismicDocument,
    SelectField,
    SliceZone
} from '@prismicio/types';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PartnerCompany } from '../modules/partner-companies/partner-companies.service';

const JOB = 'job';

@Injectable()
export class JobsService {
    constructor() {}

    getJobs(locale: string, tag?: string): Observable<PrismicDocument<Job>[]> {
        let predicates: string[] = [];

        if (tag) {
            predicates = [`[at(document.tags, ["${tag}"])]`];
        }

        return from(
            environment.prismic.client.getAllByType<PrismicDocument<Job>>(JOB, {
                lang: locale,
                fetchLinks: ['partner-company.logo'],
                orderings: { field: 'my.job.date', direction: 'desc' },
                predicates
            })
        ).pipe(
            map((documents) => {
                return documents.map((document) => {
                    document.data.uid = document.uid!;
                    return document;
                });
            })
        );
    }

    getJob(uid: string, locale: string): Observable<PrismicDocument<Job>> {
        return from(
            environment.prismic.client.getByUID<PrismicDocument<Job>>(JOB, uid, {
                lang: locale,
                fetchLinks: ['partner-company.logo', 'partner-company.organization', 'partner-company.name']
            })
        );
    }
}

export type Job = {
    uid: string;
    id: KeyTextField;
    type: JobTyp;
    title: KeyTextField;
    short_description: KeyTextField;
    date: DateField;
    street: KeyTextField;
    postal_code: KeyTextField;
    city: KeyTextField;
    state: KeyTextField;
    country: SelectField;
    highlight: BooleanField;
    tags: GroupField<Tag>;
    video: KeyTextField;
    thumbnail: ImageField;
    hidden: BooleanField;
    organization: FilledLinkToDocumentField<never, never, PartnerCompany>;
    body?: SliceZone<any>;
};

export type Tag = {
    tag: KeyTextField;
};

export enum JobTyp {
    Job = 'Job',
    Qualification = 'Qualification'
}
