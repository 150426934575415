import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LinkModule } from '../link/link.module';
import { ChangeLanguageBarComponent } from './change-language-bar.component';

const modules = [CommonModule, RouterModule, TranslateModule, LinkModule];

const components = [ChangeLanguageBarComponent];

@NgModule({
    imports: [...modules],
    declarations: [...components],
    providers: [],
    exports: [...components]
})
export class ChangeLanguageBarModule {}
