import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { RatingFormModalComponent } from './rating-form-modal.component';
import { RatingFormModalService } from './rating-form-modal.service';

const modules = [
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ProgressBarModule,
    RatingModule.forRoot()
];

const components = [RatingFormModalComponent];

@NgModule({
    imports: [...modules],
    declarations: [...components],
    exports: [...components],
    providers: [RatingFormModalService]
})
export class RatingFormModalModule {}
