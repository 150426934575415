import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationModalModule } from './modules/shared/application-modal/application-modal.module';
import { ChangeLanguageBarModule } from './modules/shared/change-language-bar/change-language-bar.module';
import { FooterModule } from './modules/shared/footer/footer.module';
import { NavigationModule } from './modules/shared/navigation/navigation.module';
import { PrismicModule } from './modules/shared/prismic/prismic.module';
import { StandardResolver } from './modules/standard/standard.resolver';
import { StandardService } from './modules/standard/standard.service';
import { AnalyticsService } from './services/analytics.service';
import { ApplicationFormService } from './services/application-form.service';
import { GlobalContentService } from './services/global-content.service';
import { JobsService } from './services/jobs.service';
import { MetaDataService } from './services/meta-data.service';
import { NavigationService } from './services/navigation.service';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ScullyLibModule.forRoot(),
        HttpClientModule,
        PrismicModule,
        ChangeLanguageBarModule,
        NavigationModule,
        FooterModule,
        ApplicationModalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        NavigationService,
        GlobalContentService,
        JobsService,
        ApplicationFormService,
        StandardResolver,
        StandardService,
        MetaDataService,
        { provide: LOCALE_ID, useValue: 'de-DE' },
        AnalyticsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

// AOT compilation support
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
